import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["courseCheckbox", "confirmButton", "confirmWrapper", "confirmModal", "messageContainer"]
  static values = { message: String }

  connect() {
    this.updateConfirmButton()
  }

  handleCourseSelect() {
    this.updateConfirmButton()
  }

  updateConfirmButton() {
    const checkedCourses = this.courseCheckboxTargets.filter(checkbox => checkbox.checked)
    this.confirmButtonTarget.disabled = checkedCourses.length === 0
  }

  confirm() {
    if (!this.confirmButtonTarget.disabled) {
      const checkedCourses = this.courseCheckboxTargets.filter(checkbox => checkbox.checked && !checkbox.disabled)
      const courseNames = checkedCourses.map(checkbox => {
        const label = document.querySelector(`label[for="course_${checkbox.value}"]`)
        return label.textContent.trim()
      }).join(", ")

      this.messageContainerTarget.innerHTML = `
        <p>${this.messageValue}</p>
        <p><strong>${courseNames}</strong></p>
      `

      this.confirmWrapperTarget.classList.add("hidden")
      this.confirmModalTarget.classList.remove("hidden")
    }
  }

  cancel() {
    this.confirmWrapperTarget.classList.remove("hidden")
    this.confirmModalTarget.classList.add("hidden")
  }
}
