import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["confirmModal", "confirmWrapper", "confirmButton", "customer", "messageContainer"]
  static values = { message: String }

  confirm() {
    console.log('confirm');

    this.confirmModalTarget.classList.remove("hidden");
    this.confirmWrapperTarget.classList.add("hidden");

    let message = this.messageValue + '\n';

    this.customerTargets.forEach((element) => {
      const checkBox = element.querySelector(".customerCheckbox");
      const customerName = element.querySelector(".customerName").innerText;

      if (checkBox.checked && !checkBox.disabled) {
        message += "- " + customerName + "\n";
      }
    });

    this.messageContainerTarget.innerText = message;
  }

  cancel(e) {
    this.confirmModalTarget.classList.add("hidden");
    this.confirmWrapperTarget.classList.remove("hidden");

    this.customerTargets.forEach(element => {
      const checkBox = element.querySelector(".customerCheckbox");

      if (!checkBox.disabled) {
        checkBox.checked = false;
      }
    });
  }

  testButtonDisabled() {
    const res = this.customerTargets.some(element => {
      const checkBox = element.querySelector(".customerCheckbox");
      return checkBox.checked
    });

    // if (res) {
    if (true) {
      this.confirmButtonTarget.removeAttribute("disabled");
    } else {
      this.confirmButtonTarget.setAttribute("disabled", "true")
    }
  }
}
