import { Controller } from "@hotwired/stimulus"
import { toastrNotification } from '../helpers/Toastr'

export default class extends Controller {

  static targets = ['customersData', 'invitationLink', 'message', 'submit', 'search', 'notFound']

  static values = {

  }

  searchCustomers(event) {
    let search = this.searchTarget.value.toLowerCase()

    let customers = document.querySelectorAll('.customer')
    let count = 0
    customers.forEach(function (customer) {
      let childs = customer.children
      let customerNamePhone = childs[1].textContent.toLowerCase()
      if (customerNamePhone.includes(search)) {
        customer.hidden = false
      }
      else {
        customer.hidden = true
        count++
      }
    });
    if (count == customers.length) {

      if (this.checkPhoneCorrectness(search) || this.checkEmailCorrectness(search)) {
        this.findCustomerOnPlatform(search)
      }
      // this.notFoundTarget.hidden = false
    }
    else {
      this.notFoundTarget.hidden = true

    }

  }

  checkPhoneCorrectness(search) {
    let phoneNumberPattern = /^\+?[1-9]\d{10,14}$/;
    if (!phoneNumberPattern.test(search)) {
      return false;
    }
    return true
  }

  checkEmailCorrectness(search) {
    let emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(search)) {
      return false;
    }
    return true;
  }

  findCustomerOnPlatform(search) {
    console.log('findCustomerOnPlatform...');
    fetch(`/api/v1/invitations/find_by_phone_or_email?search=${encodeURIComponent(search)}`)
      .then(response => response.json())
      .then(data => {
        if (data.success == true) {
          let customer = data.customer
          this.addCustomer(customer);
        }
        // data.forEach(customer => {
        //   addCustomer(customer.name);
        // });
      })
      .catch(error => console.error('Error fetching customers:', error));
  }

  addCustomer(customer) {
    let parent = document.getElementById('customers-list')

    let child = `<div class="form-check mx-1 px-1 customer">
                  <input type="checkbox" name="meeting[participant_ids][]" id="customers_id_${customer.id}" value="${customer.id}">
                  <label id="${customer.id}" class="" for="customers_id_${customer.id}">${customer.name} [${customer.phone}]</label>
                </div>`
    parent.insertAdjacentHTML('afterbegin', child);
  }

  searchCourses(event) {
    let search = this.searchTarget.value.toLowerCase()
    let courses = document.querySelectorAll('.course')
    let count = 0
    courses.forEach(function (course) {
      let childs = course.children
      let courseTitle = childs[1].textContent.toLowerCase()
      if (courseTitle.includes(search)) {
        course.hidden = false
      }
      else {
        count++
        course.hidden = true
      }
    });
    if (count == courses.length) {
      this.notFoundTarget.hidden = false
      this.addItemsTarget.disabled = true
    }
    else {
      this.notFoundTarget.hidden = true
      this.addItemsTarget.disabled = false
    }
  }

  confirmCustomers(event) {
    let customers = document.querySelectorAll('.customer')
    let message = window.t('add_customers.message') + '\n'
    let checkedItems = 0
    let disabledItems = 0
    customers.forEach(function (item) {
      let childs = item.children
      if (childs[0].checked) {
        checkedItems++
      }
      if (childs[0].disabled) {
        disabledItems++
      }
    })
    if ((checkedItems - disabledItems) > 0) {
      customers.forEach(function (item) {
        let childs = item.children
        if (childs[0].checked && !childs[0].disabled) {
          message += '- ' + childs[1].textContent + '\n'
        }
      })
      this.messageTarget.hidden = false
      this.messageTarget.innerText = message
      this.submitTarget.hidden = false
      this.addItemsTarget.hidden = true
    }
  }

  resetCustomers(event) {
    this.messageTarget.hidden = true
    this.submitTarget.hidden = true
    this.addItemsTarget.hidden = false
  }

  confirmCourses(event) {
    let courses = document.querySelectorAll('.course')
    let message = window.t('add_courses.message') + '\n'
    let checkedItems = 0
    courses.forEach(function (item) {
      let childs = item.children
      if (childs[0].checked) {
        checkedItems++
      }
    })
    if (checkedItems > 0) {
      courses.forEach(function (item) {
        let childs = item.children
        if (childs[0].checked) {
          message += '- ' + childs[1].textContent + '\n'
        }
      });
      this.messageTarget.hidden = false
      this.messageTarget.innerText = message
      this.submitTarget.hidden = false
      this.addItemsTarget.hidden = true
    }
  }

  resetCourses(event) {
    this.messageTarget.hidden = true
    this.submitTarget.hidden = true
    this.addItemsTarget.hidden = false
  }

  async copyLink(event) {
    var target = this.invitationLinkTarget
    await navigator.clipboard.writeText(target.value)
      .then(() => toastrNotification({ type: 'success', title: window.t('course.copy_invitation_link') }))
      .catch(err => toastrNotification({ type: 'error', title: window.t(`${err.name}: ${err.message}`) }))
  }


  importCustomers(event) {
    const file = event.target.files[0]
    const reader = new FileReader();
    reader.onload = function (event) {
      let dataFromFile = event.target.result
      let customers = dataFromFile.split('\n')
      console.log(customers)
      for (var i = 0; i < customers.length - 1; i++) {
        addCustomer(customers[i])
      }
    }
    reader.readAsText(file)
  }
}

function addCustomer(customer) {
  var div = document.createElement('div')
  div.className = "form-check mx-1 px-1"
  var checkbox = document.createElement('input')
  checkbox.type = "checkbox"
  checkbox.name = "customers_params[]"
  checkbox.value = customer
  checkbox.id = "customers_params_"
  var label = document.createElement('label')
  label.htmlFor = "customers_params_"
  label.appendChild(document.createTextNode(customer))
  div.appendChild(checkbox)
  div.appendChild(label)
  var customersParent = document.getElementById("customersData")
  if (customersParent) {
    customersParent.appendChild(div)
  }
}