import React from 'react'
import ReactDOM from 'react-dom'
import Invitations from './Invitations'

document.addEventListener('turbo:load', () => {
  const invitationsContainer = document.getElementById('companyInvitations')

  if (invitationsContainer) {
    const company = JSON.parse(invitationsContainer.dataset.company)

    ReactDOM.render(
      <Invitations company={company} />,
      invitationsContainer,
    );
  }

})