import { Controller } from "@hotwired/stimulus";

import Uppy from "@uppy/core"
import Dashboard from "@uppy/dashboard"
import ImageEditor from "@uppy/image-editor"
import Tus from "@uppy/tus"
import Russian from '@uppy/locales/lib/ru_RU'
import '@uppy/image-editor/dist/style.css'


export default class extends Controller {
  connect() {
    let showAddImage = this.element.dataset.showAddImage;
    this.removeExistingEditor();

    // Initialize the editor with a small delay to ensure the DOM is ready
    setTimeout(() => {
      if (showAddImage == "true") {
        this.initializeTinyMCE();
      }
      else {
        this.initializeSimpleTinyMCE();
      }
    }, 50);
  }

  disconnect() {
    // Always clean up when the controller disconnects
    this.removeExistingEditor();
  }

  removeExistingEditor() {
    const currentTextArea = this.element.dataset.id;
    if (tinymce.get(currentTextArea)) {
      tinymce.remove(`#${currentTextArea}`);
    }
  }

  initializeTinyMCE() {
    let currentTextArea = this.element.dataset.id;
    let showAddImage = this.element.dataset.showAddImage;

    tinymce.init({
      selector: `textarea#${currentTextArea}`,
      language: 'ru',
      promotion: false,
      branding: false,
      menubar: false, // Set menubar to false to hide the menu
      plugins:
        'preview importcss autolink directionality code visualblocks visualchars fullscreen image link table charmap nonbreaking anchor advlist lists help charmap',
      contextmenu: 'link image table',
      toolbar:
        'bold italic underline strikethrough fontselect fontsizeselect formatselect alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist uploadImageButton | forecolor backcolor removeformat | charmap | insertfile link anchor | table',
      toolbar_sticky: true,
      file_picker_types: 'image',
      suffix: '.min',
      relative_urls: false,
      setup: (editor) => {
        editor.ui.registry.addMenuItem('uploadImage', {
          icon: 'image',
          text: window.t('add_image'),
          onAction: () => {
            this.addImage(editor);
          }
        });

        editor.ui.registry.addButton('uploadImageButton', {
          icon: 'image',
          tooltip: window.t('add_image'),
          onAction: () => this.addImage(editor)
        });
      },
    });
  }

  initializeSimpleTinyMCE() {
    let currentTextArea = this.element.dataset.id;

    tinymce.init({
      selector: `textarea#${currentTextArea}`,
      plugins: 'link image code',
      menubar: false,
      branding: false,
      toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code'
    });
  }

  addImage(editor) {
    const locale = window.locale == "ru" ? Russian : 'en_EN'
    const imageWidth = 300;
    const imageHeight = 300;

    const uppy = new Uppy({
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: ['image/*']
      },
      autoProceed: false,
      locale: locale
    })

    // Store Uppy instance as a property of this controller
    this.uppyInstance = uppy;

    uppy.use(Tus, { endpoint: window.upload_endpoint, chunkSize: 5 * 1024 * 1024 })
    uppy.use(Dashboard, {
      proudlyDisplayPoweredByUppy: false,
      autoOpenFileEditor: true,
      hideCancelButton: true
    })
    uppy.use(ImageEditor, {
      target: Dashboard,
      quality: 1,
      cropperOptions: {
        viewMode: 1,
        background: true,
        // autoCropArea: 0.8,
        responsive: true,
        // aspectRatio: 1 / 1,
        croppedCanvasOptions: {
          width: imageWidth,
          height: imageHeight
        },
      },
      actions: {
        revert: true,
        rotate: true,
        granularRotate: true,
        flip: true,
        zoomIn: true,
        zoomOut: true,
        cropSquare: false,
        cropWidescreen: false,
        cropWidescreenVertical: false,
      },
    })

    uppy.on('upload-success', (file, response) => {
      // Get the dimensions from the file's metadata after cropping
      const width = file.meta.width || imageWidth;
      const height = file.meta.height || imageHeight;

      let fileData = {
        title: file.name,
        file_orig_name: file.name,
        file_url: response.uploadURL,
        file_size: file.size,
        file_type: file.meta.type,
        width: width,
        height: height
      }
      this.createFile(fileData, editor, uppy);
      // The modal will be closed in createFile after ensuring the URL is accessible
    });

    uppy.getPlugin('Dashboard').openModal();
  }

  createFile(fileData, editor, uppy) {
    let fileDataCreate = fileData;
    delete fileDataCreate.width;
    delete fileDataCreate.height;
    const parameters = JSON.stringify({ file: fileDataCreate });
    const token = document.getElementsByName("csrf-token")[0].content;

    // Show a waiting overlay
    this.showWaitingOverlay('Ожидание загрузки...');

    fetch('/api/v1/files.json', {
      body: parameters,
      method: 'POST',
      credentials: "include",
      headers: {
        'Content-Type': 'application/json',
        "X-CSRF-Token": token
      },
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        // Check if URL is accessible before inserting
        this.checkImageUrlAccessibility(data.url)
          .then(isAccessible => {
            // Hide waiting overlay
            this.hideWaitingOverlay();

            if (isAccessible) {
              // Insert the image with the width and height from the cropped image
              editor.insertContent(`<img src="${data.url}" width="${fileData.width}" height="${fileData.height}">`);
              console.log("Image URL is accessible:", data.url);
            } else {
              console.error("Image URL is not accessible:", data.url);
              editor.notificationManager.open({
                text: "The uploaded image URL is not accessible. Please try again.",
                type: "error"
              });
            }
            // Always close the modal, even if URL is not accessible
            if (uppy && uppy.getPlugin('Dashboard')) {
              uppy.getPlugin('Dashboard').closeModal();
            }
          })
          .catch(error => {
            // Hide waiting overlay
            this.hideWaitingOverlay();

            console.error("Error checking image URL:", error);
            // Insert image with dimensions
            editor.insertContent(`<img src="${data.url}" width="${fileData.width}" height="${fileData.height}">`);
            if (uppy && uppy.getPlugin('Dashboard')) {
              uppy.getPlugin('Dashboard').closeModal();
            }
          });
      })
      .catch(error => {
        // Hide waiting overlay
        this.hideWaitingOverlay();

        console.error("Error creating file:", error);
        if (uppy && uppy.getPlugin('Dashboard')) {
          uppy.getPlugin('Dashboard').closeModal();
        }
      });
  }

  // Method to check if an image URL is accessible with multiple retries
  checkImageUrlAccessibility(url) {
    return new Promise((resolve) => {
      let attempts = 0;
      const maxAttempts = 20;
      const intervalMs = 2000;

      const checkWithRetry = () => {
        attempts++;
        console.log(`Checking image accessibility, attempt ${attempts} of ${maxAttempts}: ${url}`);

        this.updateWaitingMessage(`Ожидание загрузки... ${attempts}/${maxAttempts}`);

        const img = new Image();
        img.onload = function () {
          console.log(`Image accessible after ${attempts} attempts: ${url}`);
          resolve(true);
        };
        img.onerror = function () {
          if (attempts < maxAttempts) {
            // Try again after interval
            setTimeout(checkWithRetry, intervalMs);
          } else {
            console.error(`Image not accessible after ${maxAttempts} attempts: ${url}`);
            resolve(false);
          }
        };
        img.src = url;
      };

      checkWithRetry();

      // Set an overall timeout in case the checks get stuck
      setTimeout(() => {
        if (attempts < maxAttempts) {
          console.warn(`Overall timeout reached for image accessibility check: ${url}`);
          resolve(true); // Default to accessible after timeout
        }
      }, (maxAttempts + 5) * intervalMs);
    });
  }

  // Create an overlay to show while waiting for image accessibility check
  showWaitingOverlay(message) {
    // Remove any existing overlay first
    this.hideWaitingOverlay();

    const overlay = document.createElement('div');
    overlay.id = 'waiting-overlay';
    overlay.style.cssText = `
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 9999;
    `;

    const messageBox = document.createElement('div');
    messageBox.id = 'waiting-message';
    messageBox.style.cssText = `
      background-color: white;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      text-align: center;
      max-width: 80%;
    `;

    const spinner = document.createElement('div');
    spinner.style.cssText = `
      border: 4px solid #f3f3f3;
      border-top: 4px solid #3498db;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      animation: spin 2s linear infinite;
      margin: 0 auto 15px auto;
    `;

    // Add the CSS animation for the spinner
    const style = document.createElement('style');
    style.textContent = `
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    `;
    document.head.appendChild(style);

    const text = document.createElement('p');
    text.textContent = message;

    messageBox.appendChild(spinner);
    messageBox.appendChild(text);
    overlay.appendChild(messageBox);
    document.body.appendChild(overlay);
  }

  // Update the waiting message
  updateWaitingMessage(message) {
    const messageElement = document.querySelector('#waiting-message p');
    if (messageElement) {
      messageElement.textContent = message;
    }
  }

  // Remove the waiting overlay
  hideWaitingOverlay() {
    const overlay = document.getElementById('waiting-overlay');
    if (overlay) {
      overlay.remove();
    }
  }
}