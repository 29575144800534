import React from 'react'
import { client } from '../Client'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import { toastrNotification } from '../../helpers/Toastr'
import TrixEditor from '../../helpers/TrixEditor'
import ImageCropper from './ImageCropper'
import noimage from '../../images/noimage.png'
import TagsInput from 'react-tagsinput'
import FileUploader from '../../helpers/FileUploader'

import 'react-tagsinput/react-tagsinput.css'
import './course-details.scss'
import { lang } from 'moment'

class Details extends React.Component {


  constructor(props) {
    super(props)
    this.descriptionRef = React.createRef();

    this.state = {
      promoVideo: props.course.promo_video_id,
      imageSrc: null,
      tags: props.course.tag_list || [],
      use_ai: props.course.use_ai,
    }
  }

  updateImageSrc = (src) => {
    this.setState({ imageSrc: src })
  }

  handlePromoVideoUpload = (promoVideoId) => {
    this.setState({ promoVideo: promoVideoId })
  }

  handleTagsChange = (tags) => {
    this.setState({ tags })
  }


  getDescription = (e) => {
    var videos_duration = this.props.course.videos_duration
    if (videos_duration > 0) {
      client.getCourseDescriptionFromGigachat(this.props.course.id)
        .then(description => {
          this.descriptionRef.current.trixInput.current.innerHTML = description
        })
    }
    else {
      this.descriptionRef.current.trixInput.current.innerHTML = window.t('course.gigachat_note')
    }

  }

  render() {
    const { course, categories, certificateTemplates } = this.props,
      { imageSrc, promoVideo, tags, use_ai } = this.state

    const tagsInputProps = {
      placeholder: window.t('course.add_tag')
    }

    const validationSchema = Yup.object().shape({
      title: Yup.string()
        .max(60, window.t('form.too_long'))
        .required(window.t('form.required')),
      sub_title: Yup.string()
        .max(150, window.t('form.too_long')),
      expiry_period: Yup.number()
        .min(0)
        .integer()
    })

    return (
      <div className="container">
        <div className="card bg-transparent border course-creator-content mb-5">
          <div className="card-body">
            <div className="bs-stepper-content">
              <h4>{window.t('course.details')}</h4>
              <hr />
              <div className="row g-4">
                <Formik
                  initialValues={{
                    title: course.title,
                    sub_title: course.sub_title || "",
                    course_category_id: course.category.id,
                    expiry_period: course.expiry_period || 0,
                    language: course.language || "",
                    description: course.description || "",
                    promo_video_id: course.promo_video_id,
                    certificate_template_id: course.certificate_template_id,
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    let data = values

                    if (imageSrc) { data['image'] = imageSrc }
                    if (promoVideo) { data['promo_video_id'] = promoVideo }
                    data['tag_list'] = tags
                    data['use_ai'] = use_ai

                    client.updateCourse(course.id, values)
                      .then((course) => {
                        if (course.id) {
                          toastrNotification({ type: 'success', title: window.t('updated') })
                          this.props.onUpdateCourse(course)
                        } else {
                          toastrNotification({ type: 'error', title: window.t('not_updated') })
                        }
                        setSubmitting(false)
                      })
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isValid,
                    isSubmitting,
                    setFieldValue
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="row g-4">
                        <div className="form-group col-md-6">
                          <label className="form-label">{window.t('course.title')}</label>
                          <div className="input-group">
                            <input
                              type="text"
                              name="title"
                              className={"form-control " + (errors.title && touched.title ? 'is-invalid' : '')}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.title}
                              placeholder={window.t('course.title_placeholder')}
                            />
                            <span className="input-group-text" id="title-addon">{values.title.length > 60 ? 0 : (60 - values.title.length)}</span>
                            {errors.title && touched.title &&
                              <div className="invalid-feedback">{errors.title}</div>
                            }
                          </div>
                        </div>
                        <div className="form-group col-md-6">
                          <label className="form-label">{window.t('course.subtitle')}</label>
                          <div className="input-group">
                            <input
                              type="text"
                              name="sub_title"
                              className={"form-control " + (errors.sub_title && touched.sub_title ? 'is-invalid' : '')}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.sub_title}
                              placeholder={window.t('course.subtitle_placeholder')}
                            />
                            <span className="input-group-text" id="subtitle-addon">{values.sub_title.length > 150 ? 0 : (150 - values.sub_title.length)}</span>

                            {errors.sub_title && touched.sub_title &&
                              <div className="invalid-feedback">{errors.sub_title}</div>
                            }
                          </div>
                        </div>

                      </div>
                      <div className="row">
                        <div className="form-group col-md-6">
                          <label className="form-label">{window.t('course.category')}</label>
                          <select
                            onChange={handleChange}
                            className="form-select bg-transparent" aria-label=".form-select-sm" data-search-enabled="true"
                            value={values.course_category_id}
                            onBlur={handleBlur}
                            name="course_category_id">
                            {categories.map(category =>
                              <option
                                key={category.id}
                                value={category.id}
                              >
                                {category.name}
                              </option>
                            )}
                          </select>
                        </div>

                        <div className="form-group col-md-6">
                          <label className="form-label">{window.t('course.expiry_period')}</label>
                          <Field as="select" name="expiry_period" className="form-select bg-transparent" aria-label=".form-select-sm" data-search-enabled="true">
                            <option value="0">{window.t('course.lifetime_access')}</option>
                            <option value="1">1 {window.t('course.month')}</option>
                            <option value="3">3 {window.t('course.few_months')}</option>
                            <option value="6">6 {window.t('course.months')}</option>
                            <option value="9">9 {window.t('course.months')}</option>
                            <option value="12">1 {window.t('course.year')}</option>
                            <option value="24">2 {window.t('course.years')}</option>
                            <option value="36">3 {window.t('course.years')}</option>
                          </Field>
                        </div>
                      </div>
                      <div className="form-group col-md-6">
                        <label className="form-label">{window.t('course.language')}</label>
                        <Field as="select"
                          name="language"
                          className="form-select bg-transparent"
                          aria-label=".form-select-sm"
                          data-search-enabled="true"
                          value={values.language}
                        >
                          <option value="en">{window.t('course.language_english')}</option>
                          <option value="ru">{window.t('course.language_russian')}</option>
                        </Field>
                      </div>

                      <div className="form-group">
                        <div>
                          <label className="form-label me-3">{window.t('course.description')}</label>
                          <a className='btn btn-info p-1 ms-3'
                            onClick={this.getDescription}
                            title={this.props.course.videos_duration > 0 ? window.t('course.gigachat_converting') : window.t('course.gigachat_empty')}
                          >
                            {window.t('course.description_by_gigachat')}
                          </a>
                        </div>
                        <TrixEditor
                          ref={this.descriptionRef}
                          value={values.description}
                          className="bg-light border border-bottom-0 rounded-top py-3"
                          onChange={e => setFieldValue('description', e)}
                        />
                      </div>

                      <div className="form-group">
                        <label className="form-label">{window.t('course.tag_list')}</label>
                        <TagsInput value={tags} onChange={this.handleTagsChange} inputProps={tagsInputProps} addOnBlur={true} />
                      </div>

                      <div className="form-group">
                        <label className="form-label">{window.t('course.image')}</label>
                        <div className="row">
                          <div className="col-sm-6 col-md-4">
                            <figure className="pos-relative mg-b-0">
                              <img src={imageSrc || course.image_url} className="img-fit-cover course-image" />
                            </figure>
                          </div>
                          <div className="col-sm-6 col-md-8">
                            <div className="mg-b-10">{window.t('course.image_requirements')}</div>
                            <ImageCropper onUpdateImage={this.updateImageSrc} />
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="form-label">{window.t('course.image_certificate')}</label>
                        <div className="row">
                          <div className="col-sm-6 col-md-4">
                            <figure className="pos-relative mg-b-0">
                              <img src={course.certificate_image_url == "" ? noimage : course.certificate_image_url} className="img-fit-cover course-image" />
                            </figure>
                          </div>
                          <div className="col-sm-6 col-md-8">
                            <label className="form-label">{window.t('course.certificate_template')}</label>
                            <select
                              onChange={handleChange}
                              className="form-select bg-transparent" aria-label=".form-select-sm" data-search-enabled="true"
                              value={values.certificate_template_id == null ? 'undefined' : values.certificate_template_id}
                              onBlur={handleBlur}
                              name="certificate_template_id"
                            >
                              <option value="">{window.t('course.not_selected')}</option>
                              {certificateTemplates.map(certificate_template =>
                                <option
                                  key={certificate_template.id}
                                  value={certificate_template.id}
                                >
                                  {certificate_template.title}
                                </option>
                              )}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="form-label">{window.t('course.promo_video')}</label>
                        <div className="row">
                          <div className="col-sm-6 col-md-3">
                            <figure className="card shadow me-4 mb-4 z-index-9 justify-content-center">
                              {course.promo_video && course.promo_video.image_url ?
                                <div className='overflow-hidden rounded-2'>
                                  <img src={course.promo_video.image_url} className="img-fit-cover course-image" />
                                  <div className="bg-overlay bg-dark opacity-6"></div>
                                  {course.promo_video.content_status != "ready" ?
                                    <div class="card-img-overlay p-1">
                                      <div class="d-flex flex-column justify-content-between h-100">
                                        <div class="row">
                                          <div class="col d-flex justify-content-start">
                                            <p class="badge bg-success"> {window.t('course.video_transcoding')} </p>
                                          </div>
                                          <div class="col course-card-rating d-flex justify-content-end">
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    :
                                    <div className="card-img-overlay d-flex align-items-start flex-column ms-3">
                                      <div className="m-auto">
                                        <a href={Routes.tutor_video_path(course.promo_video.content_id)} data-modal="true" className='btn btn-lg text-danger btn-round btn-white-shadow mb-0'>
                                          <i className="fas fa-play" style={{ color: '#38FF88' }}></i>
                                        </a>
                                      </div>
                                    </div>
                                  }
                                </div>
                                :
                                <img src={noimage} className="img-fit-cover course-image" />
                              }
                            </figure>
                          </div>
                          <div className="col-sm-6 col-md-8">
                            <div>{window.t('course.promo_video_description')}</div>
                            <FileUploader
                              onFileUploadSuccess={this.handlePromoVideoUpload}
                              buttonStyles={"btn btn-xs btn-primary mt-2"}
                              buttonTitle={window.t('course.select_promo_video')}
                              maxNumberOfFiles={1}
                              allowedFileTypes={['video/*']}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12 text-end">
                          <button type="submit" className="btn btn-primary" disabled={isSubmitting || !isValid}>
                            {window.t('save')}
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Details
