import React from 'react'
import { client } from '../Client'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { toastrNotification } from '../../helpers/Toastr'

class Price extends React.Component {

  componentDidMount() {
    // Initialize the parent with the current price value
    if (this.props.onPriceChange) {
      this.props.onPriceChange(this.props.course.price || 0);
    }
  }

  render() {
    const course = this.props.course
    const locale = window.locale || 'ru'
    const maxCoursePrice = course.author.payment_provider === 'tinkoff' ? 30000 : 1000000
    const minCoursePrice = course.author.payment_provider === 'tinkoff' ? 1000 : -1
    var discountStatus = parseInt(course.discount_price) > 0

    const validationSchema = Yup.object().shape({
      price: Yup.number()
        .required(window.t('form.required'))
        .min(minCoursePrice, window.t('form.must_be_more_then') + ' ' + minCoursePrice)
        .max(maxCoursePrice, window.t('form.must_be_less_or_equal_to') + ' ' + maxCoursePrice)
        .integer(),
      discount_price: Yup.number()
        .when([], {
          is: () => document.getElementById('enable_discount').checked,
          then: Yup.number()
            .min(minCoursePrice, window.t('form.must_be_more_then') + ' ' + minCoursePrice)
            .integer()
            .max(Yup.ref('price'), window.t('course.less_then_price'))
        })
    })

    function toggleDiscountField() {
      document.querySelector("input[name='discount_price']").toggleAttribute('hidden')
      document.querySelector(".discount-hint").toggleAttribute('hidden')
      discountStatus = !discountStatus
    }


    return (

      <div className="" id="price">
        <Formik
          initialValues={{
            price: course.price,
            discount_price: course.discount_price
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {

            // Notify parent component about price change
            if (this.props.onPriceChange) {
              this.props.onPriceChange(values.price);
            }

            if (values.discount_price == '' || values.discount_price == null) { discountStatus = false }
            if (!discountStatus) document.getElementById("enable_discount").checked = false
            let data = {}
            data['course_price_attributes'] = values

            client.updateCourse(course.id, data, discountStatus)
              .then((course) => {
                if (course.id) {
                  toastrNotification({ type: 'success', title: window.t('updated') })
                  this.props.onUpdateCourse(course)
                } else {
                  toastrNotification({ type: 'error', title: window.t('not_updated') })
                }
                setSubmitting(false)
              })
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isValid,
            isSubmitting,
          }) => {
            // Notify parent component about price change when values.price changes
            React.useEffect(() => {
              if (this.props.onPriceChange) {
                this.props.onPriceChange(values.price);
              }
            }, [values.price]);

            return (
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-4 col-sm-12">
                    <label className="form-label">{window.t('course.price')}</label>
                    <input
                      type="number"
                      min="0"
                      step="1"
                      name="price"
                      className={"form-control " + (errors.price && touched.price ? 'is-invalid' : '')}
                      onChange={(e) => {
                        handleChange(e);
                        // Optional: immediate notification on change
                        if (this.props.onPriceChange) {
                          this.props.onPriceChange(e.target.value);
                        }
                      }}
                      onBlur={handleBlur}
                      value={values.price}
                      placeholder={window.t('course.price_placeholder')}
                      disabled={!course.ready_for_publication}
                    />
                    {errors.price && touched.price &&
                      <div className="invalid-feedback">{errors.price}</div>
                    }

                  </div>
                  <div className="col-md-4 col-sm-12 mt-3 mt-md-0">
                    <label className="form-label me-3">{window.t('course.discount_price')}</label>
                    <input type="checkbox" name="enable_discount"
                      onChange={toggleDiscountField}
                      id="enable_discount"
                      defaultChecked={discountStatus} />
                    <input
                      type="number"
                      min="0"
                      step="1"
                      name="discount_price"
                      hidden={!discountStatus}
                      className={"form-control " + (errors.discount_price && touched.discount_price ? 'is-invalid' : '')}
                      onChange={handleChange}

                      onBlur={handleBlur}
                      value={values.discount_price}
                      placeholder={window.t('course.discount_price_placeholder')}
                      disabled={!course.ready_for_publication}
                    />
                    {errors.discount_price && touched.discount_price &&
                      <div className="invalid-feedback">{errors.discount_price}</div>
                    }
                    <div className='discount-hint'
                      hidden={!discountStatus}
                    >
                      {values.discount_price === 0 &&
                        <small>{window.t('course.discount_price_hint')}</small>
                      }
                      {values.discount_price > 0 && values.price > 0 &&
                        <small>{window.t('course.has_discount')} <span className="text-danger">{Math.round((values.price - values.discount_price) / values.price * 100)}%</span></small>
                      }
                    </div>
                  </div>
                  {/* {course.ready_for_publication && */}

                  <div className="col-sm-12 col-md-4 mt-md-4">
                    <button type="submit" className="btn btn-primary mt-1" disabled={isSubmitting || !isValid}>
                      {window.t('save')}
                    </button>
                  </div>
                  <div className='text-danger'><small>{course.author.payment_provider == "tinkoff" ? window.t('course.tinkoff_price_note') : ""}</small></div>
                </div>




              </form>
            );
          }}
        </Formik>
        {!course.ready_for_publication &&
          <div className="row">
            <div className="col-12">
              <a href={Routes.tutor_bank_cards_path(locale)}>{window.t('course.contract_is_needed')}</a>

              {/* {course.author.payment_provider === 'mkb'
                ? window.t('course.contract_is_needed')
                : <div>
                  {window.t('course.add_bank_card_warning')} <br />
                  <a href={Routes.tutor_bank_cards_path(locale)}>{window.t('course.add_bank_card')}</a>
                </div>
              } */}
            </div>

          </div>
        }

      </div>

    )
  }
}

export default Price