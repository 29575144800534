import axios from 'axios'
import { result } from 'lodash';

class Client {

  getNotifications() {
    return this.callApi('/api/v1/notifications.json')
      .catch((error) => {
        this.handleApiError(error);
        return [];
      });
  }

  getConversationMessages(page, conversationId, search) {
    return this.callApi("/api/v1/conversation_messages.json?conversation_id=" + conversationId + "&page=" + page + "&search=" + search)
      .catch((error) => {
        this.handleApiError(error);
        return [];
      });
  }

  getUnreadMessages(page) {
    return this.callApi("/api/v1/conversation_messages/get_unread_messages.json?page=" + page)
      .catch((error) => {
        this.handleApiError(error);
        return [];
      });
  }

  markAllMessagesRead() {
    return this.callApi("/api/v1/conversation_messages/mark_messages_read.json", {}, 'POST')
      .catch((error) => {
        this.handleApiError(error);
        return [];
      });
  }

  markCurrentConversationMessagesRead(conversationId) {
    return this.callApi("/api/v1/conversation_messages/mark_current_conversation_messages_read.json?conversation_id=" + conversationId, {}, 'POST')
      .catch((error) => {
        this.handleApiError(error);
        return [];
      });
  }

  getTutorFileStorageLimit() {
    return this.callApi("/api/v1/settings/get_storage_limit.json")
      .catch((error) => {
        this.handleApiError(error);
        return [];
      });
  }

  getFile(fileId) {
    return this.callApi('/api/v1/files/' + fileId + '.json')
      .catch((error) => {
        client.handleApiError(error)
        return {}
      })
  }

  createFile(fileData) {
    return this.callApi('/api/v1/files.json', {}, 'POST', { file: fileData })
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });
  }

  createConversationFile(fileData, conversationId) {
    return this.callApi('/api/v1/files/create_conversation_file.json', {}, 'POST', { file: fileData, conversation_id: conversationId })
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });
  }

  deleteFile(fileUrl) {
    return this.callApi('/api/v1/files/destroy_file.json', {}, 'POST', { file_url: fileUrl })
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });
  }

  updateCertificateAttachment(fileId, certificateId) {
    return this.callApi('/api/v1/certificate_templates/' + certificateId + '.json', {}, 'PUT', { file_uid: fileId })
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });
  }

  deleteCertificateAttachment(attachmentId, certificateId) {
    return this.callApi('/api/v1/certificate_templates/' + certificateId + '/attachments/' + attachmentId + '.json', {}, 'DELETE')
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });
  }

  createHomeworkAttachment(fileId, homeworkId) {
    return this.callApi('/api/v1/homeworks/' + homeworkId + '/attachments.json', {}, 'POST', { file_id: fileId })
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });
  }

  deleteHomeworkAttachment(attachmentId, homeworkId) {
    return this.callApi('/api/v1/homeworks/' + homeworkId + '/attachments/' + attachmentId + '.json', {}, 'DELETE')
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });
  }

  createHomeworkInstance(homeworkId) {
    return this.callApi(Routes.api_v1_homework_instances_path(homeworkId), {}, 'POST')
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });
  }

  getHomeworkSubmissions(homeworkId, homeworkInstanceId) {
    return this.callApi(Routes.api_v1_homework_instance_submissions_path(homeworkId, homeworkInstanceId))
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });
  }

  createHomeworkSubmission(homeworkId, homeworkInstanceId) {
    return this.callApi(Routes.api_v1_homework_instance_submissions_path(homeworkId, homeworkInstanceId), {}, 'POST')
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });
  }

  updateHomeworkSubmission(homeworkId, homeworkInstanceId, submissionId, submissionData) {
    return this.callApi(Routes.api_v1_homework_instance_submission_path(homeworkId, homeworkInstanceId, submissionId), {}, 'PUT', { submission: submissionData })
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });
  }

  deleteHomeworkSubmission(homeworkId, homeworkInstanceId, submissionId) {
    return this.callApi(Routes.api_v1_homework_instance_submission_path(homeworkId, homeworkInstanceId, submissionId), {}, 'DELETE')
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });
  }

  createHomeworkSubmissionAttachment(homeworkId, homeworkInstanceId, submissionId, fileData) {
    return this.callApi(Routes.add_attachment_api_v1_homework_instance_submission_path(homeworkId, homeworkInstanceId, submissionId), {}, 'POST', { file: fileData })
      .catch((error) => {
        client.handleApiError(error)
        return {}
      });
  }

  getCertificates() {
    return this.callApi("/api/v1/certificate_templates/")
      .catch((error) => {
        this.handleApiError(error)
        return {}
      })
  }

  getCertificate(certificate_templateId) {
    return this.callApi("/api/v1/certificate_templates/" + certificate_templateId)
      .catch((error) => {
        this.handleApiError(error)
        return {}
      })
  }

  updateCertificateTemplate(certificate_templateId, data) {
    return this.callApi('/api/v1/certificate_templates/' + certificate_templateId + '.json', {}, 'PUT', { certificate_template: data })
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  deleteCertificateTemplate(certificate_templateId) {
    return this.callApi('/api/v1/certificate_templates/' + certificate_templateId + '.json', {}, 'DELETE')
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });
  }

  setRoleForPupil(courseId, licenseId, role) {
    return this.callApi('/api/v1/courses/' + courseId + '/licenses/' + licenseId + '/set_role_for_stream.json', {}, 'PUT', { role: role })
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  getViewersAmount(courseId) {
    return this.callApi('/api/v1/courses/' + courseId + '/get_viewers_amount.json')
      .catch((error) => {
        this.handleApiError(error)
        return {}
      })
  }

  getStreamStatus(courseId) {
    return this.callApi('/api/v1/courses/' + courseId + '/get_stream_status.json')
      .catch((error) => {
        this.handleApiError(error)
        return {}
      })
  }

  getCourses() {
    return this.callApi("/api/v1/courses/")
      .catch((error) => {
        this.handleApiError(error)
        return {}
      })
  }

  getCourse(courseId) {
    return this.callApi("/api/v1/courses/" + courseId)
      .catch((error) => {
        this.handleApiError(error)
        return {}
      })
  }

  getCourseDescriptionFromGigachat(courseId) {
    return this.callApi("/api/v1/courses/" + courseId + '/get_description_from_gigachat.json')
      .catch((error) => {
        this.handleApiError(error)
        return {}
      })
  }

  generateQuestionsForLection(courseId, lectionId) {
    return this.callApi("/api/v1/courses/" + courseId + '/generate_questions_for_lection.json', {}, 'POST', { lection_id: lectionId })
      .catch((error) => {
        this.handleApiError(error)
        return {}
      })
  }

  updateCourse(courseId, data, discountStatus) {
    if (data.course_price_attributes && (!discountStatus || data.course_price_attributes.discount_price == '')) data.course_price_attributes.discount_price = '0'
    return this.callApi('/api/v1/courses/' + courseId + '.json', {}, 'PUT', { course: data })
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  deleteCourse(courseId) {
    return this.callApi('/api/v1/courses/' + courseId + '.json', {}, 'DELETE')
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });
  }

  getCoursePackageItems(courseId) {
    return this.callApi('/api/v1/courses/' + courseId + '/package_items.json')
      .catch((error) => {
        this.handleApiError(error)
        return {}
      })
  }

  createCoursePackageItems(courseId, data, updateLicenses) {
    return this.callApi('/api/v1/courses/' + courseId + '/package_items.json', {}, 'POST', { package_items: data, update_licenses: updateLicenses })
      .catch((error) => {
        this.handleApiError(error)
        return {}
      })
  }

  deleteCoursePackageItems(courseId, data, updateLicenses) {
    return this.callApi('/api/v1/courses/' + courseId + '/package_items/delete_items.json', {}, 'POST', { package_items: data, update_licenses: updateLicenses })
      .catch((error) => {
        this.handleApiError(error)
        return {}
      })
  }

  sortPackageItems(courseId, packageItemIds) {
    return client.callApi('/api/v1/courses/' + courseId + '/package_items/sort.json', {}, 'PUT', { package_items: packageItemIds })
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });
  }

  getCourseSections(courseId) {
    return this.callApi('/api/v1/courses/' + courseId + '/sections.json')
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  createCourseSection(courseId, data) {
    return this.callApi('/api/v1/courses/' + courseId + '/sections.json', {}, 'POST', { section: data })
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  updateCourseSection(courseId, sectionId, data) {
    return this.callApi('/api/v1/courses/' + courseId + '/sections/' + sectionId + '.json', {}, 'PUT', { section: data })
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  deleteCourseSection(courseId, sectionId) {
    return this.callApi('/api/v1/courses/' + courseId + '/sections/' + sectionId + '.json', {}, 'DELETE')
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  sortSections(courseId, sectionIds) {
    return client.callApi('/api/v1/courses/' + courseId + '/sections/sort.json', {}, 'PUT', { sections: sectionIds })
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });
  }

  createCourseFileLection(courseId, sectionId, lectionData, fileData) {
    return this.callApi('/api/v1/courses/' + courseId + '/sections/' + sectionId + '/lections.json', {}, 'POST', { lection: lectionData, file: fileData })
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  createCoursePromoVideo(fileData) {
    return this.callApi('/api/v1/files.json', {}, 'POST', { file: fileData })
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  updateCourseLection(courseId, sectionId, lectionId, data) {
    return this.callApi('/api/v1/courses/' + courseId + '/sections/' + sectionId + '/lections/' + lectionId + '.json', {}, 'PUT', { lection: data })
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  deleteCourseLection(courseId, sectionId, lectionId) {
    return this.callApi('/api/v1/courses/' + courseId + '/sections/' + sectionId + '/lections/' + lectionId + '.json', {}, 'DELETE')
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  addCourseLectionContent(courseId, sectionId, lectionId, contentId, contentType) {
    return this.callApi('/api/v1/courses/' + courseId + '/sections/' + sectionId + '/lections/' + lectionId + '/add_content.json', {}, 'PUT', { content_id: contentId, content_type: contentType })
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  addCourseLectionResources(courseId, sectionId, lectionId, fileIds) {
    return this.callApi('/api/v1/courses/' + courseId + '/sections/' + sectionId + '/lections/' + lectionId + '/add_resources.json', {}, 'PUT', { file_ids: fileIds })
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  deleteCourseLectionResource(courseId, sectionId, lectionId, resourceId) {
    return this.callApi('/api/v1/courses/' + courseId + '/sections/' + sectionId + '/lections/' + lectionId + '/remove_resource.json?resource_id=' + resourceId, {}, 'DELETE')
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  addCourseLectionLink(courseId, sectionId, lectionId, linkData) {
    return this.callApi('/api/v1/courses/' + courseId + '/sections/' + sectionId + '/lections/' + lectionId + '/add_link.json', {}, 'PUT', { link: linkData })
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  deleteCourseLectionLink(courseId, sectionId, lectionId, linkId) {
    return this.callApi('/api/v1/courses/' + courseId + '/sections/' + sectionId + '/lections/' + lectionId + '/remove_link.json?link_id=' + linkId, {}, 'DELETE')
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  markLectionStarted(courseId, lectionId, licenseId) {
    return this.callApi('/api/v1/courses/' + courseId + '/lection_trackers.json', {}, 'POST', { lection_id: lectionId, license_id: licenseId })
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  markLectionCompleted(courseId, lectionId, licenseId) {
    return this.callApi('/api/v1/courses/' + courseId + '/lection_trackers/complete_lection.json', {}, 'PUT', { lection_id: lectionId, license_id: licenseId })
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  sortLections(courseId, sectionId, lectionIds) {
    return client.callApi('/api/v1/courses/' + courseId + '/sections/' + sectionId + '/lections/sort.json', {}, 'PUT', { lections: lectionIds })
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });
  }

  createCourseNote(licenseId, data) {
    return this.callApi('/pupil/licenses/' + licenseId + '/notes.json', {}, 'POST', { note: data })
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  deleteCourseNote(licenseId, noteId) {
    return this.callApi('/pupil/licenses/' + licenseId + '/notes/' + noteId + '.json', {}, 'DELETE')
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  getCourseLicenses(courseId) {
    return this.callApi('/api/v1/courses/' + courseId + '/licenses.json')
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  updateCourseLicenseViewTime(courseId, licenseId) {
    return this.callApi('/api/v1/courses/' + courseId + '/licenses/' + licenseId + '/update_view_time.json', {}, 'PUT')
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  getTransformationBookStatus(bookId) {
    return this.callApi('/api/v1/transformation_course_books/' + bookId + '/get_status.json')
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  getSurveys() {
    return this.callApi('/api/v1/surveys.json')
      .catch((error) => {
        this.handleApiError(error)
        return {}
      })
  }

  getSurvey(surveyId) {
    return this.callApi('/api/v1/surveys/' + surveyId + '.json')
      .catch((error) => {
        this.handleApiError(error)
        return {}
      })
  }

  updateSurvey(surveyId, data) {
    return this.callApi('/api/v1/surveys/' + surveyId + '.json', {}, 'PUT', { survey: data })
      .catch((error) => {
        this.handleApiError(error)
        return {}
      })
  }

  deleteSurvey(surveyId) {
    return this.callApi('/api/v1/surveys/' + surveyId + '.json', {}, 'DELETE')
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });
  }

  createSurveyQuestion(surveyId, data) {
    return this.callApi('/api/v1/surveys/' + surveyId + '/questions.json', {}, 'POST', { question: data })
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  updateSurveyQuestion(surveyId, questionId, data) {
    return this.callApi('/api/v1/surveys/' + surveyId + '/questions/' + questionId + '.json', {}, 'PUT', { question: data })
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  deleteSurveyQuestion(surveyId, questionId) {
    return this.callApi('/api/v1/surveys/' + surveyId + '/questions/' + questionId + '.json', {}, 'DELETE')
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  sortSurveyQuestions(surveyId, questionIds) {
    return client.callApi('/api/v1/surveys/' + surveyId + '/questions/sort.json', {}, 'PUT', { questions: questionIds })
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });
  }

  // getSurveyAnswers(surveyId) {
  //   return this.callApi('/api/v1/surveys/' + surveyId + '/answers.json')
  //     .catch((error) => {
  //       this.handleApiError(error)
  //       return {}
  //     });
  // }

  getSurveyAnswers(surveyId) {
    return this.callApi('/api/v1/surveys/' + surveyId + '/answers.json')
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  getSurveyUserAnswers(surveyId, licenseId) {
    return this.callApi('/api/v1/surveys/' + surveyId + '/answers/user_answers.json?license_id=' + licenseId)
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  getSurveyTestResult(surveyId, licenseId) {
    return this.callApi('/api/v1/surveys/' + surveyId + '/answers/test_result.json?license_id=' + licenseId)
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  createSurveyAnswers(surveyId, licenseId, data) {
    return this.callApi('/api/v1/surveys/' + surveyId + '/answers.json', {}, 'POST', { answers: data, license_id: licenseId })
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  getRecordings() {
    return this.callApi('/api/v1/recordings.json')
      .catch((error) => {
        this.handleApiError(error)
        return {}
      });
  }

  createInvitation(invitationData) {
    return this.callApi('/api/v1/invitations.json', {}, 'POST', { invitation: invitationData })
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });
  }

  createCompanyInvitation(invitationData) {
    return this.callApi('/api/v1/company_invitations.json', {}, 'POST', { invitation: invitationData })
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });
  }

  callApi(url, headers = {}, method = 'get', body = null) {
    return axios({
      method: method,
      url: url,
      headers: Object.assign({
        accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }, headers),
      data: body,
      withCredentials: true
    })
      .then(this.checkStatus)
      .then(this.getResponseData)
      .then(this.checkSessionExpiration)
  }

  checkStatus(response) {
    if ((response.status >= 200 && response.status < 300) || response.status === 422) {
      return response;
    } else {
      const error = new Error(`HTTP Error ${response.statusText}`);
      error.status = response.statusText;
      error.response = response;
      console.log(error.response);
      throw error;
    }
  }

  getResponseData(response) {
    return response.data
  }

  checkSessionExpiration(responseData) {
    if (responseData.session_expired) {
      swal({
        title: window.t("session.expired"),
        text: window.t("session.page_will_be_reloaded"),
        type: "error",
        showCancelButton: false,
        showConfirmButton: true
      });
      Turbo.visit(window.location);
    } else {
      return responseData;
    }
  }

  handleApiError(error) {
    console.log('handle error:' + error.message);
  }

  downloadLectionFile(event, lection) {
    event.preventDefault()
    var url = lection.content.url
    var fileName = lection.content.name
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = function () {
      var urlCreator = window.URL || window.webkitURL;
      var imageUrl = urlCreator.createObjectURL(this.response);
      var tag = document.createElement('a');
      tag.href = imageUrl;
      tag.download = fileName;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
    }
    xhr.send();
  }

  checkReactViewerExt(fileName) {
    if (["htm", "html", "pdf", "doc", "docx", "xls", "xlsx", "ppt", "pptx", "txt", "jpg", "jpeg", "png", "tiff", "tif"].indexOf(this.extractFileExt(fileName)) >= 0) {
      return true
    } else {
      return false
    }
  }

  extractFileExt(name) {
    return name.match(/\.([^.]+)$|$/)[1]
  }
  getAiHelp(prompt) {
    return this.callApi('/api/v1/ai_help.json', {}, 'POST', { prompt: prompt })
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });
  }

}

export const client = new Client()
