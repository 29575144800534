import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox"]
  static values = { id: Number }

  toggle(event) {
    if (event.target !== this.checkboxTarget) {
      this.checkboxTarget.checked = !this.checkboxTarget.checked
      this.checkboxTarget.dispatchEvent(new Event('change', { bubbles: true }))
    }
  }
}
