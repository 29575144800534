import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["confirmModal", "confirmWrapper", "confirmButton", "customer", "messageContainer"]

  confirm() {
    console.log('!!!!!!!!!!!!!!!!')

    this.confirmModalTarget.classList.remove("hidden");
    this.confirmWrapperTarget.classList.add("hidden");

    let message = window.t('add_customers.message') + '\n'

    this.customerTargets.forEach((element) => {
      const checkBox = element.querySelector(".customerRadioButton");
      const customerName = element.querySelector(".customerName").innerText;

      if (checkBox.checked && !checkBox.disabled) {
        message += "- " + customerName + "\n";
      }
    });

    this.messageContainerTarget.innerText = message;
  }

  cancel(e) {
    this.confirmModalTarget.classList.add("hidden");
    this.confirmWrapperTarget.classList.remove("hidden");

    this.customerTargets.forEach(element => {
      const checkBox = element.querySelector(".customerRadioButton");

      if (!checkBox.disabled) {
        checkBox.checked = false;
      }
    });
  }

  testButtonDisabled() {
    const res = this.customerTargets.some(element => {
      const checkBox = element.querySelector(".customerRadioButton");
      return checkBox.checked
    });

    if (res) {
      this.confirmButtonTarget.removeAttribute("disabled");
    } else {
      this.confirmButtonTarget.setAttribute("disabled", "true")
    }
  }
}
