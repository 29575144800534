import React from 'react'
import { client } from '../Client'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import { toastrNotification } from '../../helpers/Toastr'
import Price from './Price'
import TrixEditor from '../../helpers/TrixEditor'
import { months } from 'moment/moment'

class Publication extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      months: 1,
      currentProductIndex: 0,
      status: props.course.status || 'draft',
      manual_distribution: props.course.manual_distribution,
      showConfirmModal: false,
      pendingSubmitAction: null,
      priceValue: props.course.price || 0,
      confirmModalMessage: ''
    };
  }

  handleStatusChange = (newStatus) => {
    if (newStatus == 'draft' || newStatus == 'temporary_block' || newStatus == 'for_free') {
      this.setState({
        status: newStatus,
        manual_distribution: false
      }, () => {
        document.querySelector('input[name="manual_distribution"]').value = this.state.manual_distribution;
        document.querySelector('input[name="manual_distribution"]').checked = this.state.manual_distribution;
      })
    }
    else
      this.setState({
        status: newStatus
      })

  }

  handleManualDistributionChange = (distribution) => {
    this.setState({ manual_distribution: distribution }, () => {
      const formikInput = document.querySelector('input[name="manual_distribution"]');
      if (formikInput) {
        formikInput.checked = distribution;
        formikInput.value = distribution;
      }
    });
  }

  hideForSaleElement = () => {
    const element = document.getElementById('price');
    if (element) {
      element.style.display = 'none';
    }
  }

  showForSaleElement = () => {
    const element = document.getElementById('price');
    if (element) {
      element.style.display = 'block';
    }
  }

  showConfirmModal = (submitAction, setSubmitting, message = 'При активации данного режима все бесплатные лицензии будут недоступны.') => {
    this.setState({
      showConfirmModal: true,
      pendingSubmitAction: submitAction,
      setSubmitting: setSubmitting,
      confirmModalMessage: message
    });
  }

  hideConfirmModal = () => {
    // When canceling, set formik's submitting state to false
    if (this.state.setSubmitting) {
      this.state.setSubmitting(false);
    }

    this.setState({
      showConfirmModal: false,
      pendingSubmitAction: null,
      setSubmitting: null
    });
  }

  confirmSaleStatus = () => {
    if (this.state.pendingSubmitAction) {
      this.state.pendingSubmitAction();
      this.hideConfirmModal();
    }
  }

  updatePriceValue = (price) => {
    this.setState({ priceValue: price });
  }

  render() {
    const course = this.props.course

    return (
      <div className="container">
        {/* Bootstrap Modal for confirmation */}
        <div className={`modal fade ${this.state.showConfirmModal ? 'show' : ''}`}
          id="confirmSaleModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="confirmSaleModalLabel"
          aria-hidden="true"
          style={{ display: this.state.showConfirmModal ? 'block' : 'none', backgroundColor: 'rgba(0,0,0,0.5)' }}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header p-2">
                <h5 className="modal-title" id="confirmSaleModalLabel">Подтверждение</h5>
                <button type="button" className="btn-close" aria-label="Close" onClick={this.hideConfirmModal}></button>
              </div>
              <div className="modal-body">
                {this.state.confirmModalMessage}
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={this.hideConfirmModal}>Отмена</button>
                <button type="button" className="btn btn-primary" onClick={this.confirmSaleStatus}>Продолжить</button>
              </div>
            </div>
          </div>
        </div>

        <div className="card bg-transparent border course-creator-content mb-5">
          <div className="card-body">
            <div className="bs-stepper-content">
              <h4>{window.t('course.publication')}</h4>
              <hr />
              <div className="row">
                <div className="col-12 offset-md-3 col-md-6">

                  <div className="bg-light border rounded p-3">
                    <form id="subscription" method="post" action={Routes.subscription_path({ product_id: course.plan_for_one.products[this.state.currentProductIndex].id })} data-turbo="false"></form>

                    <Formik
                      initialValues={{
                        status: course.status ? course.status.toString() : 'draft', message: course.temporary_block,
                        manual_distribution: this.state.manual_distribution
                      }}
                      onSubmit={(values, { setSubmitting }) => {
                        values.manual_distribution = this.state.manual_distribution;

                        const submitForm = () => {
                          client.updateCourse(course.id, values)
                            .then((course) => {
                              if (course.id) {
                                toastrNotification({ type: 'success', title: window.t('updated') })
                                this.props.onUpdateCourse(course)
                              } else {
                                toastrNotification({ type: 'error', title: window.t('not_updated') })
                              }
                              setSubmitting(false)
                            })
                            .catch(() => {
                              setSubmitting(false);
                            });
                        };

                        // Check if for_sale status with zero price
                        if (values.status === 'for_sale' && this.state.priceValue === 0) {
                          const minPrice = course.author.payment_provider === 'tinkoff' ? 1000 : 1;
                          const maxPrice = course.author.payment_provider === 'tinkoff' ? 30000 : 1000000;
                          const warningMessage = `Установлена нулевая цена курса. Для публикации на продажу необходима цена от ${minPrice} до ${maxPrice}. Продолжить?`;
                          this.showConfirmModal(submitForm, setSubmitting, warningMessage);
                          return;
                        }

                        const needsConfirmation = (values.manual_distribution === true || values.status === 'for_sale' || values.status === 'temporary_block') &&
                          (course.status === 'for_free' || course.status === 'draft');

                        needsConfirmation ? this.showConfirmModal(submitForm, setSubmitting) : submitForm();
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isValid,
                        isSubmitting
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className="form-row">
                            <div className="form-group col-md-12">
                              <h5>{window.t('course.select_publish_mode')}</h5>
                              <div role="group" aria-labelledby="my-radio-group">

                                <div className="form-check form-switch">
                                  <Field type="radio" name="status" value="draft"
                                    className={this.state.manual_distribution ? 'form-check-input form-switch-disabled' : 'form-check-input'}
                                    id="statusDraft"
                                    onClick={() => {
                                      this.hideForSaleElement();
                                      this.handleStatusChange('draft');
                                    }}
                                  />
                                  <label className="form-check-label" htmlFor="statusDraft">{window.t('course.draft')}</label>
                                </div>
                                {course.apple_user ?
                                  <div className="mb-3">
                                    <small>{window.t('course.draft_description_for_apple_user')}</small>
                                  </div>
                                  :
                                  <div className="mb-3">
                                    <small>{window.t('course.draft_description')}</small>
                                  </div>
                                }

                                <div className="form-check form-switch">
                                  <Field type="radio" name="status" value="for_free"
                                    className={this.state.manual_distribution ? 'form-check-input form-switch-disabled' : 'form-check-input'}
                                    id="statusForFree"
                                    onClick={() => {
                                      this.hideForSaleElement();
                                      this.handleStatusChange('for_free');
                                    }}
                                  />
                                  <label className="form-check-label" htmlFor="statusForFree">{window.t('course.for_free')}</label>
                                </div>
                                {course.apple_user ?
                                  <div className="mb-3">
                                    <small>{window.t('course.for_free_description_for_apple_user')}</small>
                                  </div>
                                  :
                                  <div className="mb-3">
                                    <small>{window.t('course.for_free_description')}</small>
                                  </div>
                                }

                                {course.apple_user ?
                                  <div className="form-check form-switch">
                                    <Field type="radio" name="status" value="for_sale" className="form-check-input" id="statusForSale" />
                                    <label className="form-check-label" htmlFor="statusForSale">{window.t('course.for_sale_for_apple_user')}</label>
                                  </div>
                                  :
                                  <div className="form-check form-switch">
                                    <Field type="radio" name="status" value="for_sale" className="form-check-input" id="statusForSale"
                                      onClick={() => {
                                        this.showForSaleElement();
                                        this.handleStatusChange('for_sale');
                                      }}
                                    />
                                    <label className="form-check-label" htmlFor="statusForSale">{window.t('course.for_sale')}</label>
                                  </div>
                                }
                                {course.apple_user ?
                                  <div className="row">
                                    <small>{window.t('course.for_sale_description_for_apple_user')}</small>
                                  </div>
                                  :
                                  <div className="row">
                                    <small>{window.t('course.for_sale_description')}</small>
                                  </div>
                                }

                                {!course.apple_user && this.state.status == 'for_sale' &&
                                  <Price
                                    course={course}
                                    onUpdateCourse={this.updateCourse}
                                    onPriceChange={this.updatePriceValue}
                                  />
                                }

                                {course.apple_user ?
                                  <div className="form-check form-switch">

                                  </div>
                                  :
                                  <>
                                    <div className="form-check form-switch">
                                      <Field type="checkbox"
                                        name="manual_distribution"
                                        className="form-check-input"
                                        id="statusManualDistribution"
                                        checked={this.state.manual_distribution}
                                        value={this.state.manual_distribution}
                                        onClick={(e) => {
                                          this.handleManualDistributionChange(e.target.checked);
                                        }}
                                      />
                                      <label className="form-check-label" htmlFor="statusManualDistribution">{window.t('course.subscriptions')}</label>
                                    </div>
                                    <div className='row'>
                                      <small className="form-check-label" htmlFor="statusManualDistribution">{window.t('course.subscriptions_description')}</small>
                                    </div>
                                    <div className="mt-2" hidden={!this.state.manual_distribution}>
                                      {course.author.subscription && course.author.subscription.active ?
                                        <div className='row mb-3' >
                                          <div className='col-md-7 col-sm-12'>
                                            {/* <small>{window.t('course.subscriptions_description')} </small> */}
                                            <small className='mt-1 mb-0'>{window.t('course.subscription.current_subscription')}: "{course.author.subscription.name}"<br /></small>
                                            <small className='mb-0'>{window.t('course.subscription.duration')}{course.author.subscription.period_months} {window.t('course.subscription.months')}<br /></small>
                                            {/* Цена: {course.author.subscription.price} */}
                                            {course.author.subscription.one_course ?
                                              <small className='mb-0'>{window.t('course.subscription.used')} {course.author.subscription.licenses_distributed} {window.t('course.subscription.licenses')}</small>
                                              :
                                              <small>{window.t('course.subscription.used')} {course.author.subscription.licenses_distributed} {window.t('course.subscription.from')} {course.author.subscription.licenses_limit} {window.t('course.subscription.licenses')}</small>
                                            }
                                          </div>
                                          <div className='col-md-5 col-sm-12'>
                                            <a className="btn btn-primary m-1" href={Routes.tutor_subscription_path()}>
                                              {window.t('course.subscription.update')}
                                            </a>
                                          </div>
                                        </div>
                                        :
                                        <div className='col-12 '>
                                          <div className="row mt-3">
                                            <div className="col-md-8 col-sm-12 mb-2">
                                              <small>
                                                {window.t('course.subscription.description_for_all_course')}
                                              </small>
                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                              <a className="btn btn-primary m-1" href={Routes.new_subscription_path()}>
                                                {window.t('course.all_courses_subscription')}
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      }
                                    </div>
                                  </>

                                }

                                {course.apple_user ?
                                  <>
                                    <div className="form-check form-switch" hidden>
                                      <Field type="radio" name="status" value="temporary_block"
                                        className='form-check-input'
                                        id="statusTemporaryBlock" />
                                      <label className="form-check-label" htmlFor="statusTemporaryBlock">{window.t('course.temporary_block')}</label>
                                    </div>
                                    <div className='row' hidden>
                                      <small className="form-check-label" htmlFor="statusTemporaryBlock">{window.t('course.temporary_block_message_for_apple_user')}</small>
                                    </div>
                                  </>
                                  :
                                  <>
                                    <div className="form-check form-switch">
                                      <Field type="radio" name="status" value="temporary_block"
                                        className={this.state.manual_distribution ? 'form-check-input form-switch-disabled' : 'form-check-input'}
                                        id="statusTemporaryBlock"
                                        onClick={() => {
                                          this.hideForSaleElement();
                                          this.handleStatusChange('temporary_block');
                                        }}
                                      />
                                      <label className="form-check-label" htmlFor="statusTemporaryBlock">{window.t('course.temporary_block')}</label>
                                    </div>
                                    <div className='row'>
                                      <small className="form-check-label" htmlFor="statusTemporaryBlock">{window.t('course.temporary_block_message')}</small>
                                    </div>
                                  </>
                                }

                                {values.status === 'temporary_block' &&
                                  <div className="my-2">
                                    <textarea type="text" name="message"
                                      className={"form-control mt-2 " + (errors.title && touched.title ? 'is-invalid' : '')}
                                      onChange={handleChange}
                                      value={values.message || ""} />
                                  </div>
                                }
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-12 text-end">
                              <button type="submit" className="btn btn-primary mt-3" disabled={isSubmitting || !isValid}>
                                {window.t('save')}
                              </button>
                            </div>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    )
  }
}

export default Publication
