import React, { Component } from 'react'
import { client } from './../Client'
import ActionCable from 'actioncable'
import UserAvatar from '../../helpers/UserAvatar'
import InfiniteScroll from 'react-infinite-scroll-component'
import messengerIcon from '../../images/desktop/messenger-icon.svg'
import messengerIconBright from '../../images/desktop/messenger-icon-bright.svg'

class Notifications extends Component {

  state = {
    unreadCount: 0,
    unreadMessages: [],
    unreadMessagesCount: 0,
    hasMoreUnreaMessages: true,
    unreadMessagesPage: 0
  }

  componentDidMount() {
    this.getUnreadMessages()
  }

  getUnreadMessages() {
    client.getUnreadMessages(this.state.unreadMessagesPage + 1).
      then((response) => {
        if (response.messages.length > 0) {
          this.setState(Object.assign({}, this.state, { unreadMessages: this.state.unreadMessages.concat(response.messages) }))
        }
        if (response.pagination) {
          this.setState(Object.assign({}, this.state,
            {
              unreadMessagesCount: response.pagination.total_count,
              unreadMessagesPage: response.pagination.current,
              hasMoreUnreaMessages: this.state.unreadMessages.length < response.pagination.total_count
            }
          ))
        } else {
          this.setState(Object.assign({}, this.state, { hasMoreUnreaMessages: false }))
        }
      })
  }

  markAllMessagesRead() {
    client.markAllMessagesRead().then(response => {
      Turbo.visit(window.location)
    })
  }

  render() {

    const { unreadMessages, unreadCount, unreadMessagesCount } = this.state

    return (
      <>
        <a className="link_messenger nav-item m-0 dropdown " href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
          {document.getElementById("notificationsCenter").classList.contains("icon-bright") ? <img src={messengerIconBright} /> : <img src={messengerIcon} />}
        </a>
        {unreadMessagesCount > 0 &&
          <span className="notif-badge animation-blink"></span>
        }
        <div className="dropdown-menu dropdown-animation dropdown-menu-end dropdown-menu-size-md p-0 shadow-lg border-0">
          <div className="card bg-transparent">
            <div className="card-header bg-transparent border-bottom py-3 d-flex justify-content-between align-items-center">
              <h6 className="m-0">{window.t("message.messages")}</h6>
              {unreadMessagesCount > 0 &&
                <a className="small no_link_decoration" href="#" onClick={e => this.markAllMessagesRead()}>{window.t('chat.mark_all_read')}</a>
              }
            </div>
            <div className="card-body p-0">
              {unreadMessages.length === 0 &&
                <p className="dropdown-item" >{window.t("message.no_messages")}</p>
              }
              <ul className="list-group list-unstyled list-group-flush unread-messages-container" id="unreadMessagesContainer">
                <InfiniteScroll
                  dataLength={this.state.unreadMessages.length}
                  next={this.getUnreadMessages.bind(this)}
                  hasMore={this.state.hasMoreUnreaMessages}
                  loader={<span className="ms-3">Loading...</span>}
                  endMessage={<div></div>}
                  height={this.state.unreadMessages.length > 0 ? '250px' : '20px'}
                  scrollableTarget="#unreadMessagesContainer"
                >
                  {unreadMessages.length > 0 && unreadMessages.map(message => (
                    <li key={message.id}>

                      <a href={Routes.conversation_path(message.chat_conversation_id)} className="no_link_decoration list-group-item-action border-0 border-bottom d-flex m-2" >
                        <div className="message d-flex other px-0 notification">
                          <span className="created-at" style={{ fontSize: '10px' }}>
                            <UserAvatar user={message.user} containerClasses="" />
                          </span>
                          <div className="d-flex flex-column">
                            <div className="user_name mb-0" style={{ color: 'rgb(166, 42, 169)' }}>
                              {message.user.full_name}
                            </div>
                            <span className="time">{message.created_date}
                            </span>
                            <span className="text_body">
                              {message.attachments.length > 0 &&
                                <p className="mb-0" dangerouslySetInnerHTML={{ __html: message.attachments[0].name }}></p>
                              }
                              {message.attachments.length === 0 &&
                                <p className="mb-0" dangerouslySetInnerHTML={{ __html: message.body }}></p>
                              }
                            </span>
                          </div>
                        </div>
                        {/* <div className="me-3">
                          <UserAvatar user={message.user} containerClasses="" />
                        </div>
                        <div>
                          <h6 className="mb-1">{message.user.full_name}</h6>
                          <div className="small">
                            {message.attachments.length > 0 &&
                              <p className="mb-0" dangerouslySetInnerHTML={{ __html: message.attachments[0].name }}></p>
                            }
                            {message.attachments.length === 0 &&
                              <p className="mb-0" dangerouslySetInnerHTML={{ __html: message.body }}></p>
                            }
                            <small className="text-body">{message.created_date}</small>
                          </div>
                        </div> */}
                      </a>
                    </li>
                  ))
                  }
                </InfiniteScroll>
              </ul>
            </div>
            <div className="card-footer bg-transparent border-0 py-3 text-center position-relative">
              <a href={window.base_url + Routes.conversations_path({ subdomain: null })} className='no_link_decoration'>{window.t("message.view_all_messages")}</a>
            </div>
          </div>
        </div>
      </>
    )
  }

}

export default Notifications
