import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  connect() {
    console.log('Sortable mobile connected');

    this.sortable = Sortable.create(this.element, {
      animation: 150,
      group: "shared",
      draggable: ".customers-card",
      handle: ".drag-handle",
      touchStartThreshold: 10,
      forceFallback: true,
      onEnd: this.onEnd.bind(this),
    });
  }

  onEnd(event) {
    // const ids = Array.from(this.element.querySelectorAll("tr")).map((row) =>
    //   row.getAttribute("data-id")
    // );
    const memberId = event.item.getAttribute("data-id");
    const groupFrom = event.item.getAttribute("data-group");
    const closestRow = event.item.nextElementSibling || event.item.previousElementSibling;
    const groupTo = closestRow.getAttribute("data-group");
    fetch(this.data.get("url"), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
      },
      body: JSON.stringify({ group_from_id: groupFrom, group_to_id: groupTo, member_id: memberId }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        event.item.setAttribute("data-group", groupTo)
        if (event.item.nextElementSibling && event.item.nextElementSibling.classList.contains("no_customers")) {
          event.item.nextElementSibling.style.display = "none";
        }
        if (event.item.previousElementSibling && event.item.previousElementSibling.classList.contains("no_customers")) {
          event.item.previousElementSibling.style.display = "none";
        }
      })
      .catch((error) => {
        console.error("Error updating order:", error);
      });
  }
}
