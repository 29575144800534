import React, { Component } from 'react'
import ActionCable from 'actioncable'
import { client } from './../Client'
import { Waypoint } from 'react-waypoint'
import { Element, Events, animateScroll as scroll, scroller } from 'react-scroll'
import debounce from 'lodash/debounce'
import FilePreview from '../../helpers/FilePreview'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownToggle from 'react-bootstrap/DropdownToggle'
import DropdownItem from 'react-bootstrap/DropdownItem'
import DropdownMenu from 'react-bootstrap/DropdownMenu'

import noavatar from '../../images/noavatar.jpg'
import editPen from '../../images/desktop/edit-pen.svg'
import editBasket from '../../images/desktop/edit-basket.svg'
import more2 from '../../images/desktop/more-2.svg'
import messageRead from '../../images/desktop/seen_status.svg'
import messageUnread from '../../images/desktop/seen_status_unread.svg'

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    style={{ color: "#001737" }}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
))


class ConversationMessages extends Component {

  state = {
    messages: [],
    page: 1,
    isLoading: false,
    messagesEnded: false
  }

  constructor(props) {
    super(props)
    this.loadMoreMessages = debounce(this.loadMoreMessages, 200)
    this.prevYpostion = 0
    this.userScrolledUp = false
    this.userScrolledDown = false
    this.currentDate = '';
  }

  componentDidMount() {
    this.loadMoreMessages()
    this.scrollToBottom()
    client.markCurrentConversationMessagesRead(this.props.conversation.id)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.messages !== this.state.messages) { //&& this.state.page <= 2
      this.scrollToBottom()
    }
    if (prevProps.search !== this.props.search) {
      this.setState({
        page: 1,
        messages: [],
        messagesEnded: false
      }, () => {
        this.loadMoreMessages()
      })
    }
  }

  scrollToBottom = () => {
    this.convoBottomSide.scrollIntoView({ behavior: "smooth" });
  }

  addMessage(newMessage) {
    this.setState({ messages: this.state.messages.concat([newMessage]) })
    if (newMessage.user.id === this.props.currentUserId) {
      this.scrollToBottom()
    }
  }

  deleteMessageFromList(messageId) {
    const newMessages = this.state.messages.filter(m => m.id !== messageId);
    this.setState({ messages: newMessages });
  }

  deleteMessage(ev, message) {
    if (ev) { ev.preventDefault() }
    this.props.subscription.perform('delete_conversation_message', { message_id: message.id });
  }

  loadMoreMessages() {
    if (this.state.isLoading) return
    this.setState({ isLoading: true })
    const topMessage = this.state.messages[0]
    client.getConversationMessages(this.state.page, this.props.conversation.id, this.props.search)
      .then((messages) => {
        const messagesEnded = messages.length > 0 ? false : true
        let page = this.state.page
        this.setState({
          messages: messages.concat(this.state.messages),
          page: page + 1,
          isLoading: false,
          messagesEnded: messagesEnded
        })
        if (topMessage && page !== 1) {
          scroller.scrollTo('message' + topMessage.id, { containerId: 'chat' })
        }
      })
  }

  renderWaypoint = () => {
    const { messagesEnded } = this.state

    if (!messagesEnded) {
      return (
        <Waypoint
          onEnter={this.loadMoreMessages.bind(this)}
        />
      )
    }
  }

  renderLoader = () => {
    if (this.state.isLoading && !this.state.messagesEnded) {
      return (
        <div className="row">
          <div className="col-md-12 text-center pt-3">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      )
    }
  }

  dropDownMenu(e) {
    e.preventDefault()
    let alreadyOpen = document.querySelector('.edit')
    let roomActionsOpen = document.querySelector('.room_actions')
    if (roomActionsOpen) roomActionsOpen.classList.remove('show')
    if (alreadyOpen) alreadyOpen.classList.remove('edit')
    e.target.closest('.message').classList.add('edit')
  }

  changeCurrentDate(messageDate) {
    this.currentDate = messageDate
  }

  render() {

    const { messages } = this.state
    const { canUpdateConversation, conversation, currentUserId } = this.props

    return (
      <div className="chat-content-body" id="chat">
        <div className="messages_desktop chat-group">

          {messages.length > 10 &&
            <div className="date current-date">{new Date().toLocaleString('ru', { day: 'numeric', month: 'long' })}</div>
          }
          {/* {this.renderLoader()} */}
          {/* {this.renderWaypoint()} */}
          {messages.map(message => (
            <>

              {
                this.currentDate != message.created_date && <div className="date-static" >{message.created_date}</div>
              }
              {this.changeCurrentDate(message.created_date)}
              <Element name={'message' + message.id} key={'convMessage' + message.id} id={'message' + message.id} className={message.user.id !== currentUserId ? "element msg_right" : "element msg_left"}>

                <img className="user_avatar" src={message.user.avatar_url || noavatar} alt="" />
                <div className="message d-flex other">
                  <span className="created-at" style={{ fontSize: "0" }}>{message.created_date}</span>
                  <div className="d-flex flex-column">
                    <div className="user_name">
                      <a href={message.user.subdomain ? Routes.market_tutor_path(message.user.subdomain) : ""} style={{ color: '#A62AA9', textDecoration: 'none' }}>
                        {message.user.full_name}
                      </a>
                    </div>
                    <p className="text_body" dangerouslySetInnerHTML={{ __html: message.body }} />
                    {message.attachments.map(attachment => (
                      <div className={"mb-3 " + (attachment.is_video ? "video-attachment" : "document-attachment")} key={"attachment" + attachment.id} >
                        {attachment.deleted &&
                          <div dangerouslySetInnerHTML={{ __html: `<small>*${window.t('chat.conversation.attachment_deleted')}</small>` }} />
                        }

                        {attachment.is_video && !attachment.deleted &&
                          <div className="card">
                            <a href={Routes.pupil_video_path(attachment.content_id)} data-modal="true" >
                              <img src={attachment.image_url} className="card-img-top" alt={attachment.name} />
                            </a>
                            <div className="card-body">
                              <a href={Routes.pupil_video_path(attachment.content_id)} className="card-link" data-modal="true" >
                                {attachment.name}
                              </a>
                            </div>
                          </div>
                        }
                        {!attachment.is_video && !attachment.deleted &&
                          <>
                            {/* <i className={attachment.icon + " " + attachment.icon_color}></i> */}
                            <FilePreview file={attachment} />
                          </>
                        }
                      </div>
                    ))}
                  </div>
                  {message.user.id == currentUserId ?
                    <div className="message_action d-flex flex-column" onClick={this.dropDownMenu}>
                      <img src={more2} alt="" className='more' />
                      <span className="time">
                        {new Date(message.created_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                        {conversation.convo_mode == "direct" &&
                          <img src={message.read_at == null ? messageUnread : messageRead} alt="" />
                        }
                      </span>
                    </div>
                    :
                    <div className="message_action d-flex flex-column">
                      <img src={more2} alt="" className='more' />
                      <span className="time">
                        {new Date(message.created_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                        {conversation.convo_mode == "direct" &&
                          <img src={messageRead} alt="" />
                        }
                      </span>
                    </div>
                  }

                  <ul>
                    {/* <li>
                    <a href="#" className="d-flex">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M13.2603 3.59997L5.05034 12.29C4.74034 12.62 4.44034 13.27 4.38034 13.72L4.01034 16.96C3.88034 18.13 4.72034 18.93 5.88034 18.73L9.10034 18.18C9.55034 18.1 10.1803 17.77 10.4903 17.43L18.7003 8.73997C20.1203 7.23997 20.7603 5.52997 18.5503 3.43997C16.3503 1.36997 14.6803 2.09997 13.2603 3.59997Z" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M11.8896 5.05005C12.3196 7.81005 14.5596 9.92005 17.3396 10.2" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M3 22H21" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      Редактировать
                    </a>
                  </li> */}

                    <li>
                      <a href="#" className="d-flex" onClick={(event) => this.deleteMessage(event, message)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M21 5.97998C17.67 5.64998 14.32 5.47998 10.98 5.47998C9 5.47998 7.02 5.57998 5.04 5.77998L3 5.97998" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M18.8504 9.13989L18.2004 19.2099C18.0904 20.7799 18.0004 21.9999 15.2104 21.9999H8.79039C6.00039 21.9999 5.91039 20.7799 5.80039 19.2099L5.15039 9.13989" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M10.3301 16.5H13.6601" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M9.5 12.5H14.5" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        {window.t('delete')}
                      </a>
                    </li>
                  </ul>

                </div>
              </Element>
            </>
          ))}
          <div ref={(el) => { this.convoBottomSide = el; }} ></div>
        </div>
      </div>
    )
  }

}

export default ConversationMessages
